import React from "react";
import theme from "theme";
import { Theme, Text, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"gizlilik-politikasi"} />
		<Helmet>
			<title>
				Knockout Academy
			</title>
			<meta name={"description"} content={"Knockout Academy'de birinci sınıf boks eğitimini keşfedin. Uzman koçlarımızla becerilerinizi geliştirin, formunuzu iyileştirin ve hedeflerinize ulaşın."} />
			<meta property={"og:title"} content={"Knockout Academy"} />
			<meta property={"og:description"} content={"Knockout Academy'de birinci sınıf boks eğitimini keşfedin. Uzman koçlarımızla becerilerinizi geliştirin, formunuzu iyileştirin ve hedeflerinize ulaşın."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Headernew />
		<Section padding="80px 0" sm-padding="40px 0" color="--light">
			<Override slot="SectionContent" align-items="center" />
			{"    "}{"    "}
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				{"        "}Gizlilik Politikası{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				{"        "}Knockout Academy olarak, gizliliğinize büyük önem veriyoruz. Bu gizlilik politikası, kişisel bilgilerinizin nasıl toplandığını, kullanıldığını ve korunduğunu açıklar.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Toplanan Bilgiler{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Knockout Academy'de üyelik oluşturduğunuzda veya hizmetlerimizden yararlandığınızda, adınız, e-posta adresiniz, telefon numaranız gibi kişisel bilgilerinizi toplayabiliriz. Ayrıca, web sitemizi kullanırken IP adresiniz, tarayıcı türünüz ve erişim saatleriniz gibi teknik bilgileri de toplarız.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Bilgilerin Kullanımı{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Topladığımız kişisel bilgiler, size daha iyi hizmet sunmak, üyelik işlemlerinizi yönetmek ve size özel teklifler ve bilgiler sağlamak amacıyla kullanılır. Teknik bilgiler ise web sitemizin performansını ve kullanıcı deneyimini iyileştirmek için kullanılır.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Bilgilerin Korunması{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Kişisel bilgilerinizin güvenliğini sağlamak için uygun güvenlik önlemleri alıyoruz. Bilgileriniz, yetkisiz erişim, kullanım veya ifşa edilmelere karşı korunmaktadır. Ancak, internet üzerinden bilgi iletiminin tamamen güvenli olmadığını unutmayın ve bilgilerinizi gönderirken dikkatli olun.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Bilgi Paylaşımı{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Kişisel bilgilerinizi, üçüncü taraflarla izniniz olmadan paylaşmayız. Ancak, yasal gereklilikler doğrultusunda veya hizmet sağlayıcılarımızla işbirliği yapmamız gerektiğinde bilgilerinizi paylaşabiliriz. Bu durumda, üçüncü tarafların bilgilerinizi güvenli bir şekilde kullanmasını sağlamak için gerekli önlemleri alırız.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Çerezler{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Web sitemizi kullanımınızı izlemek ve deneyiminizi kişiselleştirmek amacıyla çerezler kullanırız. Çerezler, tarayıcınıza kaydedilen küçük metin dosyalarıdır ve sizin için özel içerikler ve reklamlar sunmamıza olanak tanır. Çerezleri tarayıcı ayarlarınızdan yönetebilir veya devre dışı bırakabilirsiniz, ancak bu, web sitemizin bazı özelliklerinin çalışmasını etkileyebilir.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Değişiklikler{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Gizlilik politikamızı zaman zaman güncelleyebiliriz. Güncellemeler bu sayfada yayınlandığında yürürlüğe girer. Bu nedenle, bu sayfayı periyodik olarak kontrol etmenizi öneririz.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}İletişim{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Gizlilik politikamız hakkında herhangi bir sorunuz veya endişeniz varsa, bizimle iletişime geçebilirsiniz:{" "}
				<Link href="mailto:info@knockoutacademy.com">
					info@knockoutacademy.com
				</Link>
				{"    "}
			</Text>
		</Section>
		<Components.Footernew />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});